import React from 'react'
import { Bold} from './Texts'
import I18n from '../config/locale'
import styled from 'styled-components'
const Li = styled.li`
  color: ${props => props.theme.foregroundColor};
  @media print {
    color: ${props => props.theme.printForegroundColor};
  }
`

export const Profile = () => {
  const elements = (I18n.t("profil",{returnObjects: true}) as unknown as string[]).map(one => {
    return <Li key={"profile" + one}>{one}</Li>
  })
  return (
    <>
    <Bold>{I18n.t("profil_key")}</Bold>
    <ul>
      {elements}
    </ul>
    </>
  )
}