import React, { useState } from "react";
import styled from "styled-components";
import { H1 } from "./components/Texts";
import { SmallSpace, Tab } from "./components/Space";
import I18n from "./config/locale";
import { Address } from "./components/Address";
import { Profile } from "./components/Profile";
import { Jobs } from "./components/Jobs";
import { ThemeProvider } from "styled-components";
import { themeDark, themeWhite } from "./theme";
import { ToggleLight } from "./components/ToogleLight";
import { ProfilePicture } from "./components/ProfilePicutre";
import { Educationals } from "./components/Educationals";
import { Links, PrintLinks } from "./components/Links";
import { MoreKnowings } from "./components/MoreKnowings";
import { useTracking } from "react-tracking";
import { checkIsTokenValid } from "./utils";
const Main = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.backgroundColor};

  padding: 9px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }

  @media print {
    display: block;

    background-color: ${(props) => props.theme.printBackgroundColor};
    flex-direction: column;
  }
`;

const Left = styled.div`
  flex: 1;
  /* background-color: red; */
  display: flex;
  align-items: center;
  margin-top: 32px;
  border-right: 1px solid ${(props) => props.theme.foregroundColor};
  flex-direction: column;
  padding-right: 6px;
  @media print {
    margin-top: 0px;
    border-right: 0px solid ${(props) => props.theme.foregroundColor};
  }
`;
const Right = styled.div`
  flex: 2;
  margin-left: 16px;
  margin-bottom: 16px;
  /* background-color: green; */
`;

const LinksDiv = styled.div`
  margin-top: 12px;
  width: 181px;
`;

const App: React.FC = (props) => {
  const [theme, setTheme] = useState(themeDark);
  const [showSun, setShowSun] = useState(true);
  const [showAddress, setShowAdress] = useState(false);
  const tracking = useTracking();
  const url = new URL(window.location.href);

  const address = showAddress ? (
    <Tab>
      <Address />
    </Tab>
  ) : null;
  const token = url.searchParams.get("verify");
  if (token !== null) {
    checkIsTokenValid(token).then((b) => {
      setShowAdress(b);
      if (b) {
        tracking.trackEvent({
          action: `showAdress`,
          type: "information",
        });
      } else {
        tracking.trackEvent({
          action: `tokenInvalid`,
          type: "information",
        });
      }
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Main>
        <ToggleLight
          onClick={(showDark) => {
            tracking.trackEvent({
              action: `toogle_light_${showDark ? "dark" : "light"}`,
              type: "click",
            });
            setShowSun(showDark);
            if (showDark) {
              setTheme(themeDark);
            } else {
              setTheme(themeWhite);
            }
          }}
          showSun={showSun}
        />
        <Left>
          <Tab>
            <ProfilePicture />
          </Tab>
          <LinksDiv>
            <Links />
          </LinksDiv>
        </Left>
        <Right>
          <H1>{I18n.t("name")}</H1>
          {address}
          <SmallSpace />
          <PrintLinks />

          <Profile />
          <SmallSpace />
          <Jobs />
          <Educationals />
          <MoreKnowings />
        </Right>
      </Main>
    </ThemeProvider>
  );
};

export default App;
