import i18next from 'i18next'
import en from './locations/en'
import de from './locations/de'
import * as detectBrowserLanguage from 'browser-locale'
type LanObj = {
  [key: string]: any,
}
export const lanObj: LanObj = {
  de,
  en,
}

interface Resources {
  [key: string]: {
    translation: Object;
  },
}

const resources : Resources = {}
for (const one in lanObj) {
  resources[one] = {
    translation: lanObj[one],
  }
}

let clientLan = detectBrowserLanguage().split('-')[0]
let hasLan = false
for (const one in lanObj) {
  if (one === clientLan) {
    hasLan = true
  }
}
if (!hasLan) {
  clientLan = 'en'
}

i18next.init({
  resources,
  interpolation: {
    // React already does escaping
    escapeValue: false,
  },
  lng: 'en', // 'en' | 'es'
  // Using simple hardcoded resources for simple example
})

const i18nT = {
  t: (t: string, value?: {[key:string] : any}) => i18next.t(t, Object.assign({ lng: clientLan,  }, value)),
}
export { i18next }
export default i18nT
