import React from 'react'
import styled from 'styled-components'
import {ShowI18nArrayOfStrings} from './ShowI18nArrayOfStrings'
import {H2} from './Texts'
import {SmallSpace} from './Space'
import I18n from '../config/locale'
const MainDiv = styled.div`

`

export const MoreKnowings = () => {
  return (
    <MainDiv>
      <H2>{I18n.t("language_key")}:</H2>
      <ShowI18nArrayOfStrings i18nKey={"language"} stringBehind={false}/>
      <SmallSpace />
      <H2>{I18n.t("certifikates_key")}:</H2>
      <ShowI18nArrayOfStrings i18nKey={"certifikates"} stringBehind={false}/>
      <SmallSpace />
      <H2>{I18n.t("programminglanguage_key")}:</H2>
      <ShowI18nArrayOfStrings i18nKey={"programminglanguage"} stringBehind={true}/>

    </MainDiv>
  )
}