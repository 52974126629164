import React from 'react'
import {Span} from './Texts'

interface Props {
  cat: string
  children: any
}

export const KeyValue = (props: Props) => {
  return <Span>{props.cat}: {props.children}</Span>
}
