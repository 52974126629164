import React from 'react'
import I18n from '../config/locale'
import {Text} from './Texts'
import styled from 'styled-components'
import {Tab} from './Space'
interface Props {
  stringBehind: boolean,
  i18nKey: string
}

const MainTab = styled(Tab)`
  display: flex;
  flex-direction: column;
`

export const ShowI18nArrayOfStrings = (props: Props) => {

  const elements = (I18n.t(props.i18nKey,{returnObjects: true}) as unknown as string[])
  if (props.stringBehind){
    return <Tab><Text>{elements.join(", ")}</Text></Tab>
  }
  return (
    <div>
      <MainTab>
        {elements.map(one => {
          return <Text key={props.i18nKey + one}>{one}</Text>
        })}
      </MainTab>
    </div>
  )

}