import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import track from "react-tracking";
import {sendTrackEvent} from './utils'

const TrackedApp = track({
  app: "cv",
  pagelocation: window.location.href,
},{
  dispatch: data => {
    sendTrackEvent(data)
  }
})(App)

ReactDOM.render(<TrackedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
