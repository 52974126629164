import React from 'react'
import styled from 'styled-components'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {Github, Xing} from './Images'
import I18n from '../config/locale'
import Tooltip from '@material-ui/core/Tooltip';
import { useTracking } from 'react-tracking'
import { Bold} from './Texts'
const MainDiv = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  
  @media print {
    display: none;
  }
`
const MyLinkedInIcon = styled(LinkedInIcon)`
  color:  ${props => props.theme.foregroundColor};
  flex: 1;
  cursor: pointer;
  :hover{
    color: #ed7d31;
  }
`

const MyGithub = styled(Github)`
  color:  ${props => props.theme.foregroundColor};
  flex: 1;
  cursor: pointer;
  :hover{
    color: #ed7d31;
  }
`

const MyXing = styled(Xing)`
  color:  ${props => props.theme.foregroundColor};
  flex: 1;
  cursor: pointer;
  :hover{
    color: #ed7d31;
  }
`

const PrintMain = styled.div`
    display: none;
    @media print {
    display: block;
  }
`

export const PrintLinks = () => {
  return <PrintMain>
    <Bold>Links:</Bold>
    <ul>
      <li>
        {I18n.t('linkedIn')}
      </li>
        <li>
        {I18n.t('GitHub')}
      </li>
        <li>
        {I18n.t('xing')}
      </li>
    </ul>
  </PrintMain>
}

export const Links = () => {
  const tracking = useTracking()

  return (
    <MainDiv>
      <Tooltip title={I18n.t("linkedIn_key")}>
        <MyLinkedInIcon onClick={() => {
          openLinkNewTab(I18n.t("linkedIn"),"linkedin")
          tracking.trackEvent({
            action: "linkedin",
            type: "click"
          })
        }} />
      </Tooltip>
      <Tooltip title={I18n.t("GitHub_key")}>
        <MyGithub onClick={() => {openLinkNewTab(I18n.t("GitHub"), "github")
        tracking.trackEvent({
          action: "github",
          type: "click"
        })
      }}/>
      </Tooltip>
      <Tooltip title={I18n.t("xing_key")}>
        <MyXing onClick={() => {openLinkNewTab(I18n.t("xing"), "xing")
          tracking.trackEvent({
            action: "xing",
            type: "click"
          })
      }}/>
      </Tooltip>
    </MainDiv>
  )
  
}

const openLinkNewTab = (url: string, eventname: string) => {
  window.open(url, '_blank')
}
