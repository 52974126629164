import styled from 'styled-components'

export const SmallSpace = styled.div`
  height: 16px;
`

export const BiggerSpace = styled.div`
  height: 32px;
`

export const Tab = styled.div`
  margin-left: 16px;
`
