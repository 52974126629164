import React from 'react'
import {KeyValue} from './KeyValue'
import styled from 'styled-components'
import I18n from '../config/locale'
const Main = styled.div`
  display: flex;
  flex-direction: column;
`
export const Address = () => {
  
  return (
  <Main>
    <KeyValue cat={I18n.t("address_key")}>{getDecodeI18n("address.street")} {getDecodeI18n("address.plz")} {getDecodeI18n("address.city")}</KeyValue>
    <KeyValue cat={I18n.t("email_key")}>{getDecodeI18n("email")}</KeyValue>
    <KeyValue cat={I18n.t("phone_key")}>{getDecodeI18n("phone")}</KeyValue>
    <KeyValue cat={I18n.t("mobile_key")}>{getDecodeI18n("mobile")}</KeyValue>
    <KeyValue cat={I18n.t("birthdate_key")}>{getDecodeI18n("birthdate")}</KeyValue>
  </Main>)
}

const getDecodeI18n = (key: string): string => {
  return atob(I18n.t(key))
}

