const de = {
  name: "Fabian Simon",
  address: {
    street: "VHJpZmVsc3N0cmHfZSAxM0E=",
    plz: "NzY4MzE=",
    city: "Qmlya3dlaWxlcg==",
  },
  toogleLight_sun: "Switch to light mode",
  toogleLight_moon: "Switch to dark mode",
  address_key: "address",
  email_key: "E-Mail",
  email: "ZmFzaWJpb0BnbWFpbC5jb20=",
  phone_key: "phone",
  phone: "KzQ5IDYzNDUgOTQ5MzA5NQ==",
  mobile_key: "mobilephone",
  mobile: "KzQ5IDE3NiA3MDM4NjI3OQ==",
  birthdate_key: "Born on ",
  birthdate: "MjMuMTEuMTk5MQ==",
  xing_key: "Xing Profile",
  xing: "https://www.xing.com/profile/Fabian_Simon22",
  linkedIn_key: "LinkedIn Profile",
  linkedIn: "https://www.linkedin.com/in/fabian-simon-095128179",
  GitHub_key: "Github Profile",
  GitHub: "https://github.com/fasibio",
  profil: [
    "a well-filled stackbox",
    "Safe use of various programming languages",
    "architectural consideration of the tasks",
  ],
  profil_key: "My Profile",
  experience: "Jobs",
  stack_key: "My personal Stack",
  jobs_key: "JOBS",
  jobs: [
    {
      date: {
        from: "10/2021",
        till: "Now",
      },
      description: "Senior Full-Stack Entwickler",
      company: "Inovex GmbH",
      text: `
      As Inovex is a it service provider, 
      I work for various customers as an expert in software development and architecture, where my strengths of a well-stocked toolbox are naturally in demand. 
      Designing complex solutions and developing applications. 
      The entire workflow (DevOps) is also part of my work here.
`,
      stack: [
        "Node.js",
        "Vuejs",
        "Go",
        "Docker",
        "Kubernetes",
        "CI/CD",
        "Typescript",
        "Microservices",
        "AWS",
        "GraphQL",
        "GraphQL Federation",
        "Rust",
        "nats.io/Jetstream",
        "Metrics",
        "Terraform",
      ],
    },
    {
      date: {
        from: "03/2020",
        till: "10/2021",
      },
      description: "Senior Full-Stack Entwickler",
      company: "Quoka",
      text: `
I am a part of team witch develop and redesign the platform. 
This also means aligning and designing the enterprise architecture.
A lot of emphasis is placed on "Time2Market" and clean extensibility.
The development of context-based microservices and Faas Application is just as much a part as the connection to the existing system. 

`,
      stack: [
        "Node.js",
        "Vuejs",
        "Docker",
        "Kubernetes",
        "CI/CD",
        "Typescript",
        "Microservices",
        "AWS Lambda",
        "AWS SNS/SQS",
        "AWS S3",
      ],
    },
    {
      date: {
        from: "10/2018",
        till: "02/2020",
      },
      description: "Senior Full-Stack Developer",
      company: "Hornbach Baumarkt AG",
      text: `Work as senior full-stack developer in a small fast Team. Working deep together with other teams, to find the product market fit.
      Writing application to test them directly by the customer. (MVP´s, POC´s, Designthinking, etc)
            
      Analyse Teams, Product and process to make them faster by optimizing stuff.    
      Helping Teams to find a server architecture for the currently problem.
      
      Working with difference Agile Processes like Scrum, Kanban, Microsprint Burndown...
      Choose the right process for the Team and not Prozess over People.`,
      stack: [
        "Go",
        "GraphQL",
        "Node.js",
        "React",
        "React Native",
        "Redux",
        "mobx",
        "RXJS",
        "Docker",
        "Kubernetes",
        "CI/CD",
        "Gitlab-CI",
        "Drone",
        "Typescript",
        "Microservices",
      ],
    },
    {
      date: {
        from: "07/2016",
        till: "10/2018",
      },
      description: "App developer",
      company: "Hornbach Baumarkt AG",
      text: `
      Work as developer in the app development team.
Building apps with React-Native.
Building the backend API with GraphQL, Nodejs, Redis, Mongo etc.
Writing small Microservices.
planning the server architecture.
Writing the pipeline.`,
      stack: [
        "React Native",
        "React",
        "Docker",
        "Kubernetes",
        "Node.js",
        "Jenkins",
        "Gitlab-CI",
        "GraphQL",
        "Microservices",
        "Redis",
        "Redux",
      ],
    },
    {
      date: {
        from: "08/2012",
        till: "07/2016",
      },
      description: "Systemadministrator",
      company: "Hornbach Baumarkt AG",
      text: `
      Build application for better administrate Virtuell Server.
Administrate Server (Linux, Windows)
      `,
      stack: ["Linux Server", "Java", "C#", "Ansible", "Windows-Server"],
    },
  ],

  educational_key: "EDUCATION",
  educational: [
    {
      date: {
        from: "09/2010",
        till: "06/2012",
      },
      text: `Apprenticeship as IT specialist
by Company Softengine GmbH      
      `,
    },
    {
      date: {
        from: "09/2008",
        till: "07/2010",
      },
      text: `Höhere Berufsfachschule für IT an der Berufsbildenden
      Schule Bad Bergzabern       
      `,
    },
    {
      date: {
        from: "09/2004",
        till: "07/2008",
      },
      text: `Realschule Annweiler`,
    },
  ],

  more_infromation: "MORE INFORMATION",
  language_key: "Languages",
  language: ["German - Native", "English - Business fluent"],
  certifikates_key: "Certificates",
  certifikates: ["iSAQB® Certified Professional for Software Architecture"],
  programminglanguage_key: "programming languages",
  programminglanguage: [
    "Go",
    "Node.js",
    "React",
    "React Native",
    "Microservices",
    "GraphQL",
    "CI/CD",
    "MongoDB",
    "MYSQL",
    "Redis",
    "etc...",
  ],
};
export default de;
