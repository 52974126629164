import React from 'react'
import styled from 'styled-components'
import {H3, Bold, Span, Text,H4} from './Texts'
import {SmallSpace, Tab} from './Space'
import I18n from '../config/locale'
const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media print {
    display: block;
  }
`

export interface JobDescription {
  date: {from: string, till: string}
  description: string,
  company: string,
  text: string,
  stack: string[],
}

interface Props {
  job: JobDescription
}

export const Job = (props:Props) => {
  return (
    <MainDiv>
      <H3>{props.job.date.from} - {props.job.date.till}</H3>
      <H4>{props.job.description}</H4>
      <Span>{props.job.company}</Span>
      <SmallSpace/>
      <Tab>
        <Text>{props.job.text}</Text>
      </Tab>
      <SmallSpace/>
      <Bold>{I18n.t('stack_key')}:</Bold>
      <Tab>
        <Span>{props.job.stack.join(", ")}</Span>
      </Tab>

    </MainDiv>
  )
}
