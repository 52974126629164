import jwt from "jsonwebtoken"

export interface Event {
  action: string
} 

export const sendTrackEvent = (data: any) => {
  fetch("https://tracking.fasibio.de/tracking",{
    method: "POST",
    body: JSON.stringify(data),
  })
}


export const checkIsTokenValid = (token: string): Promise<boolean>=>{
  return fetch("/publickey",{
    method: "GET",
  }).then(e => {
    return e.text()
  }).then(d => {
    try{
      return jwt.verify(token,d)
    }catch(err){
      return ""
    }
  }).then(t => {
    if (t === ""){
      return false
    }
    return true
  })
}