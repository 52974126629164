import React from 'react'
import styled from 'styled-components'
import {H3, Text} from './Texts'
import { Tab} from './Space'

export interface EducationalDescription {
  date: {from: string, till: string}
  text: string,
}

interface Props {
  educational: EducationalDescription
}

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
`

export const Educational = (props: Props) => {
  return (
    <MainDiv>
      <H3>{props.educational.date.from} - {props.educational.date.till}</H3>
      <Tab>
        <Text>{props.educational.text}</Text>
      </Tab>
    </MainDiv>
  )
}