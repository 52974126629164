const de = {
  name: "Fabian Simon",
  address: {
    street: "VHJpZmVsc3N0cmHfZSAxM0E=",
    plz: "NzY4MzE=",
    city: "Qmlya3dlaWxlcg==",
  },
  toogleLight_sun: "Zur hellen Seite wechseln",
  toogleLight_moon: "Zur dunklen Seite wechseln",
  address_key: "Anschrift",
  email_key: "E-Mail",
  email: "ZmFzaWJpb0BnbWFpbC5jb20=",
  phone_key: "Telefon",
  phone: "KzQ5IDYzNDUgOTQ5MzA5NQ==",
  mobile_key: "Mobil",
  mobile: "KzQ5IDE3NiA3MDM4NjI3OQ==",
  birthdate_key: "Geboren am ",
  birthdate: "MjMuMTEuMTk5MQ==",
  xing_key: "Xing Profil",
  xing: "https://www.xing.com/profile/Fabian_Simon22",
  linkedIn_key: "LinkedIn Profil",
  linkedIn: "https://www.linkedin.com/in/fabian-simon-095128179",
  GitHub_key: "Github Profil",
  GitHub: "https://github.com/fasibio",
  profil: [
    "einen gut gefüllten Werkzeugkasten",
    "sichere Anwendung diverser Programmiersprachen",
    "architektonische Betrachtung der Aufgaben",
  ],
  profil_key: "Mein Profil",
  experience: "Berufserfahrung",
  stack_key: "Mein persönlicher Stack",
  jobs_key: "BERUFSERFAHRUNG",
  jobs: [
    {
      date: {
        from: "10/2021",
        till: "Heute",
      },
      description: "Senior Full-Stack Entwickler",
      company: "Inovex GmbH",
      text: `
Da es sich bei Inovex um einen Dienstleister handelt, arbeite ich für verschiedene Kunden als Experte im Bereich Softwareentwicklung und Architektur.
Dabei sind natürlich meine Stärken des gefüllten Werkzeugkoffers gefragt. Planen von komplexen Lösungen und Entwicklung von Applikationen. Sowohl im Frontend als auch im Backend.
Auch der ganze Workflow(DevOps) ist hier ein Teil meiner Tätigkeit.
`,
      stack: [
        "Node.js",
        "Vuejs",
        "Go",
        "Docker",
        "Kubernetes",
        "CI/CD",
        "Typescript",
        "Microservices",
        "AWS",
        "GraphQL",
        "GraphQL Federation",
        "Rust",
        "nats.io/Jetstream",
        "Metrics",
        "Terraform",
      ],
    },
    {
      date: {
        from: "03/2020",
        till: "10/2021",
      },
      description: "Senior Full-Stack Entwickler",
      company: "Quoka",
      text: `
Ich entwickle das Redesign der Platform mit. Hierbei heißt es auch, die Unternehmensarchitektur auszurichten und zu designen.
Dabei wird viel Wert auf "Time2Market" und saubere Erweiterbarkeit gelegt.
Die Entwicklung von Context basierend Microservices und Faas Application, ist genauso ein bestandteil, wie die Anbindung diese, an das bestehende System. 

`,
      stack: [
        "Node.js",
        "Vuejs",
        "Docker",
        "Kubernetes",
        "CI/CD",
        "Typescript",
        "Microservices",
        "AWS Lambda",
        "AWS SNS/SQS",
        "AWS S3",
      ],
    },
    {
      date: {
        from: "10/2018",
        till: "02/2020",
      },
      description: "Senior Full-Stack Entwickler",
      company: "Hornbach Baumarkt AG",
      text: `
Ich bin Teil eines Spezialisten Teams.
Wir arbeiten direkt für den Vorstand und sind eng mit den jeweiligen Entwicklerteams verzahnt um neue "Produkt market fits" zu finden.
Wir entwickeln diverse Programme, Apps und Webseiten, sowie die dazugehörigen Backends auf dem Server.
 Prämisse: maximaler Kundennutzen.
Dazu nutzen wir alle aktuellen Methoden z.B.: MVP´s, POC´s, Designthinking.
Des Weiteren analysieren und optimieren wir Produkte,Prozesse, ganze Teamstrukturen bis hin zur zugrunde
liegenden Architektur.
Dabei arbeiten wir mit verschiedenen agilen Prozessen wie
z.B.:
Scrum, Kanban, Microsprint Burndown.
Nach dem Motto:
"Choose the right process for the team and do not put the process over people."
      `,
      stack: [
        "Go",
        "GraphQL",
        "Node.js",
        "React",
        "React Native",
        "Redux",
        "mobx",
        "RXJS",
        "Docker",
        "Kubernetes",
        "CI/CD",
        "Gitlab-CI",
        "Drone",
        "Typescript",
        "Microservices",
      ],
    },
    {
      date: {
        from: "07/2016",
        till: "10/2018",
      },
      description: "App Entwickler",
      company: "Hornbach Baumarkt AG",
      text: `
      Entwickler im App Team.
      Entwicklung von Apps mit React Native.
      Mein Fokus lag dabei auf dem Entwickeln der Backendstruktur und der API.
      Verwendetet Tools: GraphQL, Node.js, Redis, Mongo.
      Für diesen Bereich plante ich die Server Architektur und entwickelte viele kleine Microservices.
      Im Kontext : continuous deployment, um maximale Geschwindigkeit zur Produktrealisierung zu erreichen      
      `,
      stack: [
        "React Native",
        "React",
        "Docker",
        "Kubernetes",
        "Node.js",
        "Jenkins",
        "Gitlab-CI",
        "GraphQL",
        "Microservices",
        "Redis",
        "Redux",
      ],
    },
    {
      date: {
        from: "08/2012",
        till: "07/2016",
      },
      description: "Systemadministrator",
      company: "Hornbach Baumarkt AG",
      text: `
      Administration der Serverlandschaft von Hornbach (Linux, Windows).
      Entwicklung erster Applikationen um die Administration der virtuellen Server besser zu verwalten und zu überwachen.
      `,
      stack: ["Linux Server", "Java", "C#", "Ansible", "Windows-Server"],
    },
  ],

  educational_key: "BILDUNGSWEG",
  educational: [
    {
      date: {
        from: "09/2010",
        till: "06/2012",
      },
      text: `Ausbildung zum Fachinformatiker,
Schwerpunkt Anwendungsentwicklung
bei Firma Softengine GmbH      
      `,
    },
    {
      date: {
        from: "09/2008",
        till: "07/2010",
      },
      text: `Höhere Berufsfachschule für IT an der Berufsbildenden
      Schule Bad Bergzabern       
      `,
    },
    {
      date: {
        from: "09/2004",
        till: "07/2008",
      },
      text: `Realschule Annweiler`,
    },
  ],

  more_infromation: "WEITERE KENNTNISSE",
  language_key: "Sprachen",
  language: ["Deutsch - Muttersprache", "Englisch - Verhandlungssicher"],
  certifikates_key: "Zertifikate",
  certifikates: ["iSAQB® Certified Professional for Software Architecture"],
  programminglanguage_key: "Programmiersprachen",
  programminglanguage: [
    "Go",
    "Node.js",
    "React",
    "React Native",
    "Microservices",
    "GraphQL",
    "CI/CD",
    "MongoDB",
    "MYSQL",
    "Redis",
    "uvm...",
  ],
};
export default de;
