import React from 'react'
import I18n from '../config/locale'
import { JobDescription, Job } from './Job'
import {BiggerSpace,SmallSpace} from './Space'
import {H2} from './Texts'
export const Jobs = () => {
  const elements = (I18n.t("jobs",{returnObjects: true}) as unknown as JobDescription[]).map((one, i) => {
    return (
      <div key={"job" + one.date.from + one.date.till } >
        <Job job={one} />
        <SmallSpace/>
      </div>
    )
  })
  return (
    <>
      <BiggerSpace />
    <H2 pageBreakBefore="verso">{I18n.t("jobs_key")}</H2>
    {elements}
    </>
  )
}
