import styled from 'styled-components'


export const H1 = styled.h1`
  color: #ed7d31;
`

interface PrintPageProps {
  pageBreakBefore?: "auto" | "always" | "avoid" | "left" | "right" | "recto" | "verso"
}

export const H2 = styled.h2<PrintPageProps>`
  @media print {
    color: ${props => props.theme.printForegroundColor};
    page-break-after: ${props => props.pageBreakBefore ?props.pageBreakBefore : 'unset' };
  }
  color: #ed7d31;
`

export const H3 = styled.h3`
  color: ${props => props.theme.foregroundColor};
  
`
export const H4 = styled.h4`
color: #ed7d31;
`

export const Span = styled.span`
  color: ${props => props.theme.foregroundColor};
  @media print {
    color: ${props => props.theme.printForegroundColor};
  }
`

export const Bold = styled(Span)`
  && {
    font-weight: bold;
  }
`

export const Text = styled(Span)`
 && {
   white-space: pre-line;
 }
`
