import React from 'react'
import I18n from '../config/locale'
import {Educational,EducationalDescription} from './Educational'
import {BiggerSpace} from './Space'
import {H2} from './Texts'


export const Educationals = () => {
  const elements = (I18n.t("educational",{returnObjects: true}) as unknown as EducationalDescription[]).map(one => {
    return (
      <Educational key={"educational" + one.date.from + one.date.till} educational={one} />
    )
  })

  return (
    <>
    <BiggerSpace />
    <H2>{I18n.t("educational_key")}</H2>
    {elements}
    </>
  )

}